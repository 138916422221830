import React from "react";
import "./css/faq.css";
import { Link } from "react-router";
import { EditCMSWrapper } from "../EditCMS/EditCMSWrapper";

export const FAQ = () => {
  return (
    <>
      <section id="center" className="center_o clearfix">
        <div className="container">
          <div className="row">
            <div className="center_o1 text-center clearfix">
              <div className="col-sm-12">
                <h2 className="mgt">Faq</h2>
                <h5>
                  <Link to="/">Home</Link> / Faq
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="faq" className="clearfix">
        <div className="container">
          <div className="row">
            <div className="faq_1 clearfix">
              <div className="col-sm-4">
                <div className="faq_1l clearfix">
                  <ul className="nav_1 mgt">
                    {[1, 2, 3, 4, 5].map((item, index) => (
                      <li
                        key={index}
                        className={index === 0 ? "active" : ""}
                      >
                        <Link
                          data-toggle="tab"
                          to={`#menu${index}`}
                        >
                          <EditCMSWrapper
                            dataKey={`faqTab${index + 1}`}
                          />
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-sm-8">
                <div className="faq_1r clearfix">
                  <div className="tab-content clearfix">
                    {[1, 2, 3, 4, 5].map((item, index) => (
                      <div
                        key={index}
                        id={`menu${index}`}
                        className={`tab-pane fade clearfix ${
                          index === 0 ? "active in" : ""
                        }`}
                      >
                        <div className="click clearfix">
                          <h4 className="col_1 mgt">
                            <EditCMSWrapper
                              dataKey={`faqTabTitle${index + 1}`}
                            />
                          </h4>
                          <p>
                            <EditCMSWrapper
                              dataKey={`faqTabContent${index + 1}`}
                            />
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="faq_o" className="clearfix">
        <div className="container">
          <div className="row">
            <div className="faq_o1 text-center clearfix">
              <div className="col-sm-12">
                <h3 className="mgt">
                  <EditCMSWrapper dataKey="askQuestionTitle" />
                </h3>
                <p>
                  <EditCMSWrapper dataKey="askQuestionDescription" />
                </p>
              </div>
            </div>
            <div className="faq_o2 clearfix">
              {[
                { key: "name", placeholder: "Name" },
                { key: "email", placeholder: "Email" },
                { key: "phone", placeholder: "Phone" },
                { key: "subject", placeholder: "Subject" },
                { key: "captcha", placeholder: "2+9 = ?" },
              ].map((field, index) => (
                <div
                  className={`faq_o2i clearfix ${
                    field.key === "captcha" ? "col-sm-12" : "col-sm-6"
                  }`}
                  key={index}
                >
                  <div className="faq_o2il clearfix">
                    <input
                      placeholder={field.placeholder}
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div>
              ))}
              <div className="faq_o2i clearfix">
                <div className="col-sm-12">
                  <div className="faq_o2il clearfix">
                    <textarea
                      placeholder="Message"
                      className="form-control form_1"
                      defaultValue={""}
                    />
                    <h6 className="bold">
                      <Link className="button" to="#">
                        <EditCMSWrapper dataKey="contactUsButton" />
                      </Link>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
