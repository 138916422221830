import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Stack from '@mui/material/Stack';
import type { } from '@mui/x-charts/themeAugmentation';
import type { } from '@mui/x-data-grid/themeAugmentation';
import type { } from '@mui/x-date-pickers/themeAugmentation';
import * as React from 'react';
import { Outlet } from 'react-router-dom';
import AppNavbar from './components/AppNavbar';
import Header from './components/Header';
import SideMenu from './components/SideMenu';
import AppTheme from './theme/AppTheme';
import "./Dashboard.css"
import {
  chartsCustomizations,
} from './theme/customizations';
const xThemeComponents = {
  ...chartsCustomizations,
};

// Create and export dashboard context
export const dashboardContext = React.createContext<any>({
  mainListItems: [],
  secondaryListItems: [],
})

interface ListItem {
  text: string // The display text of the list item
  icon?: React.ReactNode // An optional icon for the item
  link?: string // An optional URL or route path
  reactElement?: React.ReactNode // An optional icon for the item
}
type MainListItems = ListItem[]


export default function Dashboard(props: {
  disableCustomTheme?: boolean
  mainListItems: MainListItems
  secondaryListItems: MainListItems
  userData: object
  logoutFunction : () => void
  logo: string
  siteName: string
}) {
  return (
    <dashboardContext.Provider
    value={props}
  >
    <AppTheme {...props} themeComponents={xThemeComponents}>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: 'flex' }}>
        <SideMenu />
        <AppNavbar />
        {/* Main content */}
        <Box
          component="main"
          sx={(theme) => ({
            flexGrow: 1,
            backgroundColor: theme
              ? `rgba(${theme.palette.background.default} / 1)`
              : "white",
            overflow: 'auto',
          })}
        >
          <Stack
            spacing={2}
            sx={{
              alignItems: 'center',
              mx: 3,
              pb: 5,
              mt: { xs: 8, md: 0 },
            }}
          >
              <Header />
              <Outlet />
          
            
          </Stack>
        </Box>
      </Box>
      </AppTheme>
      </dashboardContext.Provider>

  );
}
