export const siteTitle = "Agriculture" //TODO


export const email = "agriculture@gmail.com" //TODO
export const phoneNumber = "+216 99 888 777" //TODO
export const address = "Sousse" //TODO

// Socials
export const LinkedIn = "https://www.linkedin.com/" //TODO
export const Instagram = "https://www.instagram.com/" //TODO
export const Facebook = "https://www.facebook.com/" //TODO
export const YouTube = "https://www.youtube.com/" //TODO
export const Twitter = "https://twitter.com/" //TODO
