import React from "react"
import { Link } from "react-router"
import { EditCMSWrapper } from "../EditCMS/EditCMSWrapper"
export const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <section id="footer">
      <div className="container">
        <div className="row">
          <div className="footer_1 clearfix">
            <div className="col-sm-4 space_all">
              <div className="footer_1i clearfix">
                <h3 className="mgt">
                  <i className="fa fa-leaf col_1" />{" "}
                  <EditCMSWrapper key="footer1"  />
                  <br />
                  <span className="col_2 font_14">
                    {" "}
                    <EditCMSWrapper key="footer2"  />
                  </span>
                </h3>
                <p>
                  {" "}
                  <EditCMSWrapper key="footer3"  />
                </p>
                <h6>
                  <Link className="button" to="/about">
                    ABOUT US
                  </Link>
                </h6>
              </div>
            </div>
            <div className="col-sm-4 space_all">
              <div className="footer_1i1 text-center clearfix">
              <EditCMSWrapper
                      imageProps={{  height: 70, width: 100}}
                      type="image"
                      dataKey="footer4"
                    />
              <EditCMSWrapper
                      imageProps={{  height: 70, width: 100}}
                      type="image"
                      dataKey="footer5"
                    />
              <EditCMSWrapper
                      imageProps={{  height: 70, width: 100}}
                      type="image"
                      dataKey="footer6"
                />
                <br />
                <EditCMSWrapper
                      imageProps={{  height: 70, width: 100}}
                      type="image"
                      dataKey="footer7"
                    />
              <EditCMSWrapper
                      imageProps={{  height: 70, width: 100}}
                      type="image"
                      dataKey="footer8"
                    />
              <EditCMSWrapper
                      imageProps={{  height: 70, width: 100}}
                      type="image"
                      dataKey="footer9"
                />
                <br/>  <EditCMSWrapper
                      imageProps={{  height: 70, width: 100}}
                      type="image"
                      dataKey="footer10"
                    />
              <EditCMSWrapper
                      imageProps={{  height: 70, width: 100}}
                      type="image"
                      dataKey="footer11"
                    />
              <EditCMSWrapper
                      imageProps={{  height: 70, width: 100}}
                      type="image"
                      dataKey="footer12"
                />
                <br/>
              </div>
            </div>
            <div className="col-sm-4 space_all">
              <div className="footer_1i2 clearfix">
                <h4 className="mgt">
                  {" "}
                  <EditCMSWrapper key="footer13"  />
                </h4>
                <hr className="hr_1 mgl" />
                <p>
                  {" "}
                  <EditCMSWrapper key="footer14"  />
                </p>
                <h6>
                  <Link className="col_1" to="#">
                    <EditCMSWrapper key="footer15"  />
                  </Link>
                </h6>
                <hr />
                <p>
                  {" "}
                  <EditCMSWrapper key="footer16"  />
                </p>
                <h6>
                  <Link className="col_1" to="#">
                    <EditCMSWrapper key="footer17"  />
                  </Link>
                </h6>
              </div>
            </div>
          </div>
          <div className="footer_2 clearfix">
            <div className="col-sm-4 space_all">
              <div className="head_t1i2 bor_l1 clearfix">
                <div className="col-sm-1 space_all">
                  <div className="head_t1i2l clearfix">
                    <span className="col_1">
                      <i className="fa fa-phone" />
                    </span>
                  </div>
                </div>
                <div className="col-sm-11">
                  <div className="head_t1i2r clearfix">
                    <h5 className="mgt">
                      {" "}
                      <EditCMSWrapper key="footer18"  />
                    </h5>
                    <h6>
                      <EditCMSWrapper key="footer19"  />
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 space_all">
              <div className="head_t1i2 bor_l1 bor_r1 clearfix">
                <div className="col-sm-1 space_all">
                  <div className="head_t1i2l clearfix">
                    <span className="col_1">
                      <i className="fa fa-envelope" />
                    </span>
                  </div>
                </div>
                <div className="col-sm-11">
                  <div className="head_t1i2r clearfix">
                    <h5 className="mgt">
                      {" "}
                      <EditCMSWrapper key="footer20"  />
                    </h5>
                    <h6>
                      {" "}
                      <EditCMSWrapper key="footer21"  />
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 space_all">
              <div className="head_t1i2  bor_r1 clearfix">
                <div className="col-sm-1 space_all">
                  <div className="head_t1i2l clearfix">
                    <span className="col_1">
                      <i className="fa fa-map-marker" />
                    </span>
                  </div>
                </div>
                <div className="col-sm-11">
                  <div className="head_t1i2r clearfix">
                    <h5 className="mgt">
                      {" "}
                      <EditCMSWrapper key="footer22"  />
                    </h5>
                    <h6>
                      {" "}
                      <EditCMSWrapper key="footer23"  />
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer_3 text-center clearfix">
            <div className="col-sm-12">
              <p className="mgt">
                © {currentYear}{" "}
                <EditCMSWrapper key="footer24"  />. All
                Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
