import React, { useEffect } from 'react';

import "./LightRadiationMeter.css"

const DashboardLuminosity = () => {
  useEffect(() => {
    const luminositySpan = document.getElementById('luminosity');
    const sunCircle = document.getElementById('sunCircle');
    const sunRays = document.querySelectorAll('.line');
    const percentageTexts = document.querySelectorAll('.percentage');
    const rays = [
      document.getElementById('ray-1'),
      document.getElementById('ray-2'),
      document.getElementById('ray-3'),
      document.getElementById('ray-4'),
      document.getElementById('ray-5'),
      document.getElementById('ray-6'),
      document.getElementById('ray-7'),
      document.getElementById('ray-8'),
      document.getElementById('ray-9'),
      document.getElementById('ray-10'),
    ];

    function updateLuminosity(lum) {
      lum = Math.max(0, Math.min(100, lum));
      luminositySpan.textContent = lum;

      sunCircle.setAttribute('fill', '#FFEB3B');
      sunRays.forEach(ray => ray.setAttribute('stroke', '#FFEB3B'));

      updatePercentageColor(lum);
    }

    function updatePercentageColor(lum) {
      percentageTexts.forEach((text, index) => {
        const percentageValue = parseInt(text.textContent);

        text.setAttribute('fill', '#FFEB3B');
        rays[index].setAttribute('stroke', '#FFEB3B');

        if (lum >= 0 && lum <= 10) {
          document.getElementById('percent-2')?.setAttribute('fill', '#4CAF50');
          document.getElementById('ray-2')?.setAttribute('stroke', '#4CAF50');
        } else if (lum >= 11 && lum <= 20) {
          document.getElementById('percent-3')?.setAttribute('fill', '#4CAF50');
          document.getElementById('ray-3')?.setAttribute('stroke', '#4CAF50');
        } else if (lum >= 21 && lum <= 30) {
          document.getElementById('percent-4')?.setAttribute('fill', '#4CAF50');
          document.getElementById('ray-4')?.setAttribute('stroke', '#4CAF50');
        } else if (lum >= 31 && lum <= 40) {
          document.getElementById('percent-5')?.setAttribute('fill', '#4CAF50');
          document.getElementById('ray-5')?.setAttribute('stroke', '#4CAF50');
        } else if (lum >= 41 && lum <= 50) {
          document.getElementById('percent-6')?.setAttribute('fill', '#FF9800');
          document.getElementById('ray-6')?.setAttribute('stroke', '#FF9800');
        } else if (lum >= 51 && lum <= 60) {
          document.getElementById('percent-7')?.setAttribute('fill', '#FF9800');
          document.getElementById('ray-7')?.setAttribute('stroke', '#FF9800');
        } else if (lum >= 61 && lum <= 70) {
          document.getElementById('percent-8')?.setAttribute('fill', '#FF9800');
          document.getElementById('ray-8')?.setAttribute('stroke', '#FF9800');
        } else if (lum >= 71 && lum <= 80) {
          document.getElementById('percent-9')?.setAttribute('fill', '#F44336');
          document.getElementById('ray-9')?.setAttribute('stroke', '#F44336');
        } else if (lum >= 81 && lum <= 90) {
          document.getElementById('percent-10')?.setAttribute('fill', '#F44336');
          document.getElementById('ray-10')?.setAttribute('stroke', '#F44336');
        } else {
          document.getElementById('percent-1')?.setAttribute('fill', '#F44336');
          document.getElementById('ray-1')?.setAttribute('stroke', '#F44336');
        }
      });
    }

    let luminosity = 0;
    const intervalId = setInterval(() => {
      luminosity = (luminosity + 10) % 110;
      updateLuminosity(luminosity);
    }, 2000);

    return () => {
      clearInterval(intervalId); // Cleanup the interval on component unmount
    };
  }, []);

  return (
    <div className="lightRadiationMeter">
      <div className="sun-symbol" id="sunSymbol">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
          <circle cx="50" cy="50" r="20" fill="#4CAF50" id="sunCircle"/>
          <line id="ray-1" x1="50" y1="50" x2="50" y2="10" stroke="#FFEB3B" className="line" />
          <text className="percentage" x="50" y="5" id="percent-1">100%</text>
          <line id="ray-2" x1="50" y1="50" x2="50" y2="10" stroke="#FFEB3B" className="line" transform="rotate(36,50,50)" />
          <text className="percentage" x="20" y="10" id="percent-2">90%</text>
          <line id="ray-3" x1="50" y1="50" x2="50" y2="10" stroke="#FFEB3B" className="line" transform="rotate(72,50,50)" />
          <text className="percentage" x="5" y="35" id="percent-3">80%</text>
          <line id="ray-4" x1="50" y1="50" x2="50" y2="10" stroke="#FFEB3B" className="line" transform="rotate(108,50,50)" />
          <text className="percentage" x="5" y="62" id="percent-4">70%</text>
          <line id="ray-5" x1="50" y1="50" x2="50" y2="10" stroke="#FFEB3B" className="line" transform="rotate(144,50,50)" />
          <text className="percentage" x="20" y="86" id="percent-5">60%</text>
          <line id="ray-6" x1="50" y1="50" x2="50" y2="10" stroke="#FFEB3B" className="line" transform="rotate(180,50,50)" />
          <text className="percentage" x="50" y="95" id="percent-6">50%</text>
          <line id="ray-7" x1="50" y1="50" x2="50" y2="10" stroke="#FFEB3B" className="line" transform="rotate(216,50,50)" />
          <text className="percentage" x="80" y="86" id="percent-7">40%</text>
          <line id="ray-8" x1="50" y1="50" x2="50" y2="10" stroke="FFEB3B" className="line" transform="rotate(252,50,50)" />
          <text className="percentage" x="96" y="62" id="percent-8">30%</text>
          <line id="ray-9" x1="50" y1="50" x2="50" y2="10" stroke="#FFEB3B" className="line" transform="rotate(288,50,50)" />
          <text className="percentage" x="96" y="35" id="percent-9">20%</text>
          <line id="ray-10" x1="50" y1="50" x2="50" y2="10" stroke="#FFEB3B" className="line" transform="rotate(324,50,50)" />
          <text className="percentage" x="82" y="10" id="percent-10">10%</text>
        </svg>
      </div>
      <div className="temperature-info">
        <p>Current Luminosity: <span id="luminosity">50</span>%</p>
      </div>
    </div>
  );
};

export default DashboardLuminosity;
