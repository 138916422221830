import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { dashboardContext } from '../Dashboard';


export default function MenuContent() {

  const { mainListItems, secondaryListItems } =
    React.useContext(dashboardContext)
  
  const location = useLocation()
  
  
  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: "space-between" }}>
      <List dense>
        {mainListItems.map((item, index) => {
          const isSelected = location?.pathname === item?.link; // Check if the current route matches the item's link
          return (
            <Link key={index} to={item?.link}>
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton selected={isSelected}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            </Link>
          );
        })}
      </List>

      <List dense>
        {secondaryListItems.map((item, index) => {
          const isSelected = location?.pathname === item?.link; // Check if the current route matches the item's link
          return (
            <Link key={index} to={item?.link}>
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton selected={isSelected}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            </Link>
          );
        })}
      </List>
    </Stack>
  );
}
