import React, { useState, useEffect } from "react";
import "./HumidityDrop.css"; // Create a CSS file for styles

const HumidityDrop = () => {
  const [humidity, setHumidity] = useState(90);

  useEffect(() => {
    const interval = setInterval(() => {
      const newHumidity = Math.floor(Math.random() * 101); // Generate random humidity
      updateHumidity(newHumidity);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const updateHumidity = (humidity) => {
    // Clamp humidity between 0 and 100
    const clampedHumidity = Math.max(0, Math.min(100, humidity));
    setHumidity(clampedHumidity);
  };

  // Calculate height and determine colors dynamically
  const fillHeight = (humidity / 100) * 200; // Filling height relative to the drop size
  let background = "";
  let textColor = "";

  if (humidity < 30) {
    background = "linear-gradient(to top, #ffeb3b, #ff9800)"; // Low humidity
    textColor = "#ff9800";
  } else if (humidity < 70) {
    background = "linear-gradient(to top, #2196f3, #1e88e5)"; // Normal humidity
    textColor = "#1e88e5";
  } else {
    background = "linear-gradient(to top, #f44336, #d32f2f)"; // High humidity
    textColor = "#d32f2f";
  }

  return (
    <div className="drop-container">
      {/* Water drop */}
      <div className="drop">
        {/* Humidity level inside the drop */}
        <div
          className="humidity-level"
          style={{
            height: `${fillHeight}px`,
            background,
          }}
        />
      </div>

      {/* Display humidity value */}
      <div
        className="humidity-value"
        style={{ color: textColor }}
      >
        Humidity : {humidity}%
      </div>
    </div>
  );
};

export default HumidityDrop;
