import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import Grid from '@mui/material/Grid2';
import { Box } from "@mui/system";
import MainGrid from "../Dashboard/components/MainGrid";

// Define the RectangleBox component's props
interface RectangleBoxProps {
  title: string;
  subtitle: string;
  currentValue: string; // New prop for the current value
  imageUrl: string;
  boxStyle?: React.CSSProperties;
  titleStyle?: React.CSSProperties;
  subtitleStyle?: React.CSSProperties;
  imageStyle?: React.CSSProperties;
  currentValueStyle?: React.CSSProperties; // New style prop for current value
  linkTo: string; // New prop to define the link for the card
}

// RectangleBox component
const RectangleBox: React.FC<RectangleBoxProps> = ({
  title,
  subtitle,
  currentValue,
  imageUrl,
  boxStyle,
  titleStyle,
  subtitleStyle,
  imageStyle,
  currentValueStyle,
  linkTo,
}) => {
  return (
    <Link to={linkTo} style={{ textDecoration: 'none' }}>
      <Card
        sx={{
          width: "100%",
          height: "auto", // Height will adjust based on content
          transition: "transform 0.3s ease, box-shadow 0.3s ease", // Add smooth animation
          "&:hover": {
            transform: "scale(1.05)", // Slightly enlarge on hover
            boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)", // Add shadow on hover
          },
          ...boxStyle,
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ textAlign: "center" }} // Align text to the center
        >
          {/* Image */}
          <CardMedia
            component="img"
            image={imageUrl}
            alt={title}
            sx={{
              width: "100%",
              height: "150px", // Fixed height for the image
              objectFit: "contain", // Ensure the image maintains its aspect ratio without cropping
              borderRadius: "8px",
              ...imageStyle,
            }}
          />
          <CardContent sx={{ mt: 2 }}> {/* Added margin-top for separation */}
            {/* New title for the current value */}
            <Typography
              variant="h5" // Use h5 or another variant to make it bigger
              sx={{
                fontWeight: "bold",
                marginBottom: "8px",
                ...currentValueStyle,
                fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" }, // Adjust font size for different screen sizes
                color: "#333",
                // textDecoration: "underline", // Add underline to the current value
              }}
            >
              {currentValue}
            </Typography>

            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                marginBottom: "8px",
                ...titleStyle,
                fontFamily: "'Inter', sans-serif", // Apply Inter font
                fontSize: { xs: "1.2rem", sm: "1.5rem" }, // Adjust font size for smaller screens
              }}
            >
              {title}
            </Typography>

            <Typography
              variant="subtitle1"
              color="textSecondary"
              sx={{
                ...subtitleStyle,
                fontSize: { xs: "0.9rem", sm: "1rem" },
                fontFamily: "'Inter', sans-serif", // Apply Inter font
              }}
            >
              {subtitle}
            </Typography>
          </CardContent>
        </Grid>
      </Card>
    </Link>
  );
};

// Home component rendering the RectangleBox components with the specified images and text
export const Home = () => {
  const data = [
    {
      title: "Humidity, Temperature and Electrical Conductivity",
      subtitle: "Current humidity, temperature and electrical conductivity in your area.",
      currentValue: "25°C", // Example current value
      imageUrl: "/images/dashboard/temperature.webp", // Update with the correct image path
      boxStyle: {},
      titleStyle: { color: "#ff6347" },
      subtitleStyle: { fontStyle: "italic" },
      currentValueStyle: { color: "#333" },
      imageStyle: {},
      linkTo: "../HT_EC", // Example route
    },
    {
      title: "NPK Levels",
      subtitle: "The Azote, Phosphorus, and Potassium levels.",
      currentValue: "N: 12, P: 8, K: 15", // Example current value
      imageUrl: "/images/dashboard/NPK.webp", // Update with the correct image path
      boxStyle: {},
      titleStyle: { color: "#ff6347" },
      subtitleStyle: { fontStyle: "italic" },
      currentValueStyle: { color: "#333" },
      imageStyle: {},
      linkTo: "../npk", // Example route
    },
    {
      title: "Wind Speed",
      subtitle: "Current wind speed measured in your area.",
      currentValue: "15 km/h", // Example current value
      imageUrl: "/images/dashboard/wind_speed.webp", // Update with the correct image path
      boxStyle: {},
      titleStyle: { color: "#ff6347" },
      subtitleStyle: { fontStyle: "italic" },
      currentValueStyle: { color: "#333" },
      imageStyle: {},
      linkTo: "../wind_speed", // Example route
    },
    {
      title: "Light Radiation",
      subtitle: "Light radiation intensity in your area.",
      currentValue: "180 W/m²", // Example current value
      imageUrl: "/images/dashboard/light_radiation.webp", // Update with the correct image path
      boxStyle: {},
      titleStyle: { color: "#ff6347" },
      subtitleStyle: { fontStyle: "italic" },
      currentValueStyle: { color: "#333" },
      imageStyle: {},
      linkTo: "../light_radiation", // Example route
    },
  ];

  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" } }}>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Summary
      </Typography>
      <Grid
        container
        spacing={2}
        columns={12}
        sx={{ mb: (theme) => theme.spacing(2) }}
      >
        {data.map((card, index) => (
          <Grid key={index} size={{ xs: 12, sm: 6, lg: 6 }}>
            <RectangleBox {...card} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
