import * as React from "react"
import * as ReactDOM from "react-dom/client"
import { StyledEngineProvider } from "@mui/material/styles"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import { AppProvider } from "./context/AppContext"

ReactDOM.createRoot(document.querySelector("#root")!).render(
  // <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <AppProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AppProvider>
    </StyledEngineProvider>
  // </React.StrictMode>
)
