import React, { useEffect, useState } from "react"
import { Stack, TextField } from "@mui/material"
import { convertToBase64 } from "../../utils/convertToBase64"

import { saveWebData } from "../../api/axiosFunctions"
import { CustomButton } from "../Button/CustomButton"

interface EditCMSProps {
  dataKey: string
  type?: "text" | "image"
  webData: object
  imageProps : object
}

export const EditCMS: React.FC<EditCMSProps> = ({
  dataKey,
  type = "text",
  webData,imageProps
}) => {
  if (typeof webData !== "object") return <></>

  const isImage = type === "image"
  // const localizedField = isImage ? dataKey : dataKey + "_" + i18n.language
  const localizedField = isImage ? dataKey : dataKey + "_" + "en-US"
  const [editOn, setEditOn] = useState(false)
  const [data, setData] = useState(webData[localizedField] || "")
  const originalData = webData[localizedField]

  const handleToggle = () => setEditOn(!editOn)

  const handleCancel = () => {
    setEditOn(false)
    setData(originalData || "")
  }

  const handleSave = () => {
    setEditOn(false)
    const updatedData = { [localizedField]: data }
    saveWebData(updatedData)
  }

  const handleImageChange = async (files: FileList | null) => {
    if (!files || files.length === 0) return

    const base64Image = await convertToBase64(files[0], true)

    if (typeof base64Image === "string") {
      setData(base64Image)
      setEditOn(true)
    } else {
      console.error("Unexpected result from convertToBase64: not a string")
    }
  }

  const handleFileExplorerOpen = () => {
    const inputElem = document.getElementById(
      `changeImage${dataKey}`
    ) as HTMLInputElement | null
    inputElem?.click()
  }

  useEffect(() => {
    setData(webData[localizedField] || "")
  }, [webData, localizedField])

  const renderContent = () => {
    if (type === "text") {
      return editOn ? (
        <TextField
          fullWidth
          multiline
          rows={4}
          value={data}
          onChange={(e) => setData(e.target.value)}
          variant="outlined"
        />
      ) : (
        <span style={{marginLeft : "0"}} onDoubleClick={handleToggle}>
          {data || "Placeholder"}
        </span>
      )
    }

    if (type === "image") {
      return (
        <>
          <input
            type="file"
            accept="image/*"
            id={`changeImage${dataKey}`}
            style={{ display: "none" }}
            onChange={(e) => handleImageChange(e.target.files)}
          />
          <span onDoubleClick={handleFileExplorerOpen}>
            {data ? (
              <img {...imageProps} src={data} alt="Selected" style={{ maxWidth: "100%" }} />
            ) : (
              <>
                <img {...imageProps} src={"https://placehold.co/400x250"} alt="Selected" style={{ maxWidth: "100%" }} />
                {/* <span>Double-click to add an image</span> */}
              </>
            )}
          </span>
        </>
      )
    }

    return null
  }

  return (
    <>
      {renderContent()}
      {editOn && (
        <Stack direction="row" spacing={2} style={{ marginTop: "10px" }}>
          <CustomButton text="Save" onClick={handleSave} />
          <CustomButton text="Cancel" onClick={handleCancel} />
        </Stack>
      )}
    </>
  )
}

export default EditCMS
