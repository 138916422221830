import { Box, Card, CardContent, Grid2, Typography } from "@mui/material"
import React, { useState } from "react"
import HumidityDrop from "../../reusable components/HumidityDrop/HumidityDrop"
import ReactSpeedometerComponent from "../../reusable components/ReactSpeedmeter/ReactSpeedometerComponent"
import ThermometerComponent from "../../reusable components/Thermometer/ThermometerComponent"

import CustomizedDataGrid from "../Dashboard/components/CustomizedDataGrid"
import MainGridLayout from "../Dashboard/components/MainGridLayout"
import PageViewsBarChart from "../Dashboard/components/PageViewsBarChart"
import SessionsChart from "../Dashboard/components/SessionsChart"
import StatCard, { StatCardProps } from "../Dashboard/components/StatCard"

const data: StatCardProps[] = [
  {
    title: "Humidity",
    value: "14",
    interval: "Last 30 days",
    trend: "up",
    data: [
      200, 24, 220, 260, 240, 380, 100, 240, 280, 240, 300, 340, 320, 360, 340,
      380, 360, 400, 380, 420, 400, 640, 340, 460, 440, 480, 460, 600, 880, 920,
    ],
  },
  {
    title: "Temperature",
    value: "325",
    interval: "Last 30 days",
    trend: "down",
    data: [
      1640, 1250, 970, 1130, 1050, 900, 720, 1080, 900, 450, 920, 820, 840, 600,
      820, 780, 800, 760, 380, 740, 660, 620, 840, 500, 520, 480, 400, 360, 300,
      220,
    ],
  },
  {
    title: "Electrical Conductivity",
    value: "200",
    interval: "Last 30 days",
    trend: "neutral",
    data: [
      500, 400, 510, 530, 520, 600, 530, 520, 510, 730, 520, 510, 530, 620, 510,
      530, 520, 410, 530, 520, 610, 530, 520, 610, 530, 420, 510, 430, 520, 510,
    ],
  },
]

function HT_EC() {
  const [hoveredCard, setHoveredCard] = useState(null)

  const handleMouseOver = (card) => setHoveredCard(card)
  const handleMouseOut = () => setHoveredCard(null)

  const cardStyles = (card) => ({
    width: "100%",
    maxWidth: 400,
    textAlign: "center",
    transition: "transform 0.3s, box-shadow 0.3s",
    transform: hoveredCard === card ? "scale(1.05)" : "scale(1)",
    boxShadow: hoveredCard === card ? "0px 8px 16px rgba(0,0,0,0.2)" : "none",
    margin: "auto",
  })

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 3,
        flexWrap: "wrap",
        marginTop: 3,
      }}
    >
      {/* Humidity Drop Card */}
      <Card
        sx={cardStyles("humidity")}
        onMouseOver={() => handleMouseOver("humidity")}
        onMouseOut={handleMouseOut}
      >
        <CardContent
          sx={{
            height: "100%",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Humidity Monitor
          </Typography>
          <HumidityDrop />
        </CardContent>
      </Card>

      {/* Temperature Monitor Card */}
      <Card
        sx={cardStyles("thermometer")}
        onMouseOver={() => handleMouseOver("thermometer")}
        onMouseOut={handleMouseOut}
      >
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Temperature Monitor
          </Typography>
          <ThermometerComponent
            theme="light"
            value="18"
            max="100"
            steps="5"
            format="°C"
            size="large"
            height="300"
          />
        </CardContent>
      </Card>

      {/* Electrical Conductivity Card */}
      <Card
        sx={cardStyles("Electrical Conductivity")}
        onMouseOver={() => handleMouseOver("Electrical Conductivity")}
        onMouseOut={handleMouseOut}
      >
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Electrical Conductivity
          </Typography>
          <ReactSpeedometerComponent
            maxValue={120}
            minValue={-100}
            height={190}
            width={290}
            value={100}
            needleTransition="easeQuadIn"
            needleTransitionDuration={1000}
            needleColor="red"
            startColor="green"
            segments={10}
            endColor="blue"
          />
        </CardContent>
      </Card>
      <MainGridLayout>
        <>
          {data.map((card, index) => (
            <Grid2 key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
              <StatCard {...card} />
            </Grid2>
          ))}
          <Grid2 size={{ xs: 12, md: 6 }}>
            <SessionsChart
              title="Humidity, Temperature and Electrical Conductivity"
              subTitle="Humidity, Temperature and Electrical Conductivity per day for the last 30 days"
            />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <PageViewsBarChart
              title="Humidity, Temperature and Electrical Conductivity"
              subTitle="Humidity, Temperature and Electrical Conductivity per day for the last 6 months"
            />
          </Grid2>
          <Grid2 size={{ xs: 12, lg: 12 }}>
            <CustomizedDataGrid />
          </Grid2>
        </>
      </MainGridLayout>
    </Box>
  )
}

export default HT_EC
