import React from 'react'
import { EditCMSWrapper } from '../../EditCMS/EditCMSWrapper'
import { Link } from 'react-router-dom'

function QualityComponent() {
  return (
    <section id="quality">
    <div className="quality_m clearfix">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="service_h1 service_h1o text-center">
              <h2 className="col">
                <EditCMSWrapper dataKey="quality1" />
              </h2>
              <span className="block_icon">
                <i className="fa fa-leaf" />
              </span>
              <p className="col_3">
                <EditCMSWrapper dataKey="quality2" />
              </p>
            </div>
          </div>
          <div className="col-sm-12 space_all">
            <div className="quality_1">
              <div className="col-sm-2">
                <div className="quality_1i text-center">
                  <span>
                    <Link to="/">
                      <i className="fa fa-leaf" />
                    </Link>
                  </span>
                  <h4 className="mgt">
                    <EditCMSWrapper dataKey="quality3" />
                  </h4>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="quality_1i text-center">
                  <span>
                    <Link to="/">
                      <i className="fa fa-tree" />
                    </Link>
                  </span>
                  <h4 className="mgt">
                    <EditCMSWrapper dataKey="quality4" />
                  </h4>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="quality_1i text-center">
                  <span>
                    <Link to="/">
                      <i className="fa fa-male" />
                    </Link>
                  </span>
                  <h4 className="mgt">
                    <EditCMSWrapper dataKey="quality5" />
                  </h4>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="quality_1i text-center">
                  <span>
                    <Link to="/">
                      <i className="fa fa-female" />
                    </Link>
                  </span>
                  <h4 className="mgt">
                    <EditCMSWrapper dataKey="quality6" />
                  </h4>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="quality_1i text-center">
                  <span>
                    <Link to="/">
                      <i className="fa fa-truck" />
                    </Link>
                  </span>
                  <h4 className="mgt">
                    <EditCMSWrapper dataKey="quality7" />
                  </h4>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="quality_1i text-center">
                  <span>
                    <Link to="/">
                      <i className="fa fa-phone" />
                    </Link>
                  </span>
                  <h4 className="mgt">
                    <EditCMSWrapper dataKey="quality8" />
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default QualityComponent