import axios from "axios"
import { axiosInterceptorResponse } from "./axiosInterceptorResponse"

export const isLocalHost = process.env.NODE_ENV === "development"

const productionUrl = ["https://agriculture-server-8jdu.onrender.com"]
export const isProduction = productionUrl.includes(window.location.origin)

export var baseURL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8001/"
    : isProduction
    ? "https://agriculture-server-8jdu.onrender.com"
    : "https://agriculture-server-8jdu.onrender.com"

export const axiosAPI = axios.create({ baseURL })

export const axiosAPIHook = (method, path, data, then) => {
  axiosAPI[method](path, data)
    .then((data) => then && then(data))
    .catch((e) => console.log(e))
}

export const axiosAPIHook2 = (x) => x.catch((e) => console.log(e))

axiosInterceptorResponse()

/* //! -------------- Auth ------------------ */
export const signIn = (formData) => axiosAPI.post("/auth/signin", formData)

export const forgetPassword = (formData) =>
  axiosAPI.post(`/auth/forgetPassword`, formData)

export const resetPassword = (formData) =>
  axiosAPI.post(`/auth/resetPassword`, formData)

export const checkPassword = (id, password) =>
  axiosAPI.post(`/auth/checkPassword/${id}`, password)

/* #region User */

export const updateSelfUser = (updatedUser) =>
  axiosAPI.patch(`/user/updateSelfUser/updateSelfUser`, updatedUser)

/* #endregion */

/* #region Notification */
export const fetchNotificationQuery = () =>
  axiosAPI.post("/notification/fetchNotificationQuery")

export const createNotification = (newNotification) =>
  axiosAPI.post("/notification/", newNotification)

export const updateNotification = (id, updatedNotification) =>
  axiosAPI.patch(`/notification/${id}`, updatedNotification)

export const deleteNotification = (id) => axiosAPI.delete(`/notification/${id}`)
export const deleteAllNotification = (id) =>
  axiosAPI.delete(`/notification//deleteAllNotification/delete`)
/* #endregion */

export const fetchWebData =  () =>  axiosAPI.get(`/getWebData`)
export const saveWebData = (updatedData) =>
  axiosAPI.post("/saveWebData", updatedData)
