import { axiosAPI } from "./axiosFunctions"

const returnErrorPath = ["signin", "user", "ecommerceOrder", "blog"]
const models = [
  "user",
  "product",
  "category",
  "claim",
  "establishment",
  "order",
  "booking",
  "ecommerceorder",
]
const methods = {
  POST: "added",
  PATCH: "updated",
  DELETE: "deleted",
}

export const axiosInterceptorResponse = () => {
  axiosAPI.interceptors.response.use(
    function (response) {
      // const role = store.getState().auth?.authData?.role
      // endLoading()
      // const totalCount = response.headers.get("X-Total-Count")
      // if (totalCount) setReduxTotalCount(parseInt(totalCount))

      // const requestUrl = response.config.url
      // const checkPostForGet = requestUrl.split("/")[2]
      // const methodUsed = response.config.method.toUpperCase()

      // // Check if the request URL contains one of the models
      // const modelName = models.find((model) => requestUrl.includes(model))

      // if (role)
      //   if (modelName && methods[methodUsed]) {
      //     if (methodUsed === "POST" && checkPostForGet) {
      //     } else {
      //       const successMessage = `${modelName}| |${methods[methodUsed]}.`
      //       feedbackSuccess(successMessage)
      //     }
      //   }

      return response.data
    }
    // function (error) {
    //   if (!error?.response?.data) console.log(error)
    //   feedbackError(error?.response?.data || "Erreur serveur.")
    //   endLoading()
    //   const url = error?.config?.url
    //   if (returnErrorPath.find((path) => url.includes(path)))
    //     return Promise.reject(error)
    // }
  )
}
