import React, { createContext, useContext, useState } from "react";

interface AppContextValue {
  webData: object;
  setValue: React.Dispatch<React.SetStateAction<{ webData: object }>>;
}

const AppContext = createContext<AppContextValue | null>(null);

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, setState] = useState<{ webData: object }>({ webData: {} });

  return (
    <AppContext.Provider value={{ ...state, setValue: setState }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};
