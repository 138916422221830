import { Box, Card, CardContent, Typography } from "@mui/material"
import React, { useState } from "react"
import DashboardLuminosity from "../../reusable components/../reusable components/Meters/LightRadiationMeter/LightRadiationMeter"
import RadialGauge from "../../reusable components/../reusable components/Meters/RadialGauge"

import MainGridLayout from "../Dashboard/components/MainGridLayout"
import { StatCardProps } from "../Dashboard/components/StatCard"


import { Grid2 } from "@mui/material"

import CustomizedDataGrid from "../Dashboard/components/CustomizedDataGrid"
import PageViewsBarChart from "../Dashboard/components/PageViewsBarChart"
import SessionsChart from "../Dashboard/components/SessionsChart"
import StatCard from "../Dashboard/components/StatCard"

const data: StatCardProps[] = [
  {
    title: "Light Radiation",
    value: "14",
    interval: "Last 30 days",
    trend: "up",
    data: [
      200, 24, 220, 260, 240, 380, 100, 240, 280, 240, 300, 340, 320, 360, 340,
      380, 360, 400, 380, 420, 400, 640, 340, 460, 440, 480, 460, 600, 880, 920,
    ],
  },
]

function LightRadiationPage() {
  const [hoveredCard, setHoveredCard] = useState(null)

  const handleMouseOver = (card) => setHoveredCard(card)
  const handleMouseOut = () => setHoveredCard(null)

  const cardStyles = (card) => ({
    width: "100%",
    maxWidth: 400,
    textAlign: "center",
    transition: "transform 0.3s, box-shadow 0.3s",
    transform: hoveredCard === card ? "scale(1.05)" : "scale(1)",
    boxShadow: hoveredCard === card ? "0px 8px 16px rgba(0,0,0,0.2)" : "none",
    margin: "auto",
  })

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 3,
        flexWrap: "wrap",
        marginTop: 3,
      }}
    >
      {/* Light Radiation Card */}
      <Card
        sx={cardStyles("lightRadiation")}
        onMouseOver={() => handleMouseOver("lightRadiation")}
        onMouseOut={handleMouseOut}
      >
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Light Radiation
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DashboardLuminosity />
          </Box>
        </CardContent>
      </Card>

      {/* Radial Gauge Card */}
      <Card
        sx={cardStyles("radialGauge")}
        onMouseOver={() => handleMouseOver("radialGauge")}
        onMouseOut={handleMouseOut}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Radial Gauge
          </Typography>
          <RadialGauge value={50} />
        </CardContent>
      </Card>

      <MainGridLayout>
        <>
          {data.map((card, index) => (
            <Grid2 key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
              <StatCard {...card} />
            </Grid2>
          ))}
          <Grid2 size={{ xs: 12, md: 6 }}>
            <SessionsChart
              title="Light Radiation"
              subTitle="Light Radiation per day for the last 30 days"
            />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <PageViewsBarChart
              title="Light Radiation"
              subTitle="Light Radiation per day for the last 6 months"
            />
          </Grid2>
          <Grid2 size={{ xs: 12, lg: 12 }}>
            <CustomizedDataGrid />
          </Grid2>
        </>
      </MainGridLayout>
    </Box>
  )
}

export default LightRadiationPage
