import React from "react"
import { Link } from "react-router"
import ProjectComponent from "./components/ProjectComponent"

export const Project = () => {
  return (
    <>
      <section id="center" className="center_o clearfix">
        <div className="container">
          <div className="row">
            <div className="center_o1 text-center clearfix">
              <div className="col-sm-12">
                <h2 className="mgt">Our Project</h2>
                <h5>
                  <Link to="/">Home</Link> / Our Project
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ProjectComponent />
    </>
  )
}
