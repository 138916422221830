import React from 'react'
import { EditCMSWrapper } from '../../EditCMS/EditCMSWrapper'

function AboutComponent() {
  return (
    <section id="about_h">
    <div className="container">
      <div className="row">
        <div className="about_h1 clearfix">
          <div className="col-sm-7">
            <div className="about_h1l clearfix">
              <h3 className="mgt">
                <EditCMSWrapper dataKey="about_h1" />
              </h3>
              <p>
                <EditCMSWrapper dataKey="about_h2" />
              </p>
              <div className="about_h1li clearfix">
                <EditCMSWrapper
                  imageProps={{ height: 100, width: 100 }}
                  type="image"
                  dataKey="about_h3"
                />
                <EditCMSWrapper
                  imageProps={{ height: 100, width: 100 }}
                  type="image"
                  dataKey="about_h4"
                />
                <EditCMSWrapper
                  imageProps={{ height: 100, width: 100 }}
                  type="image"
                  dataKey="about_h5"
                />
                <EditCMSWrapper
                  imageProps={{ height: 100, width: 100 }}
                  type="image"
                  dataKey="about_h6"
                />
                <EditCMSWrapper
                  imageProps={{ height: 100, width: 100 }}
                  type="image"
                  dataKey="about_h7"
                />
                <EditCMSWrapper
                  imageProps={{ height: 100, width: 100 }}
                  type="image"
                  dataKey="about_h8"
                />
              </div>
              {/* <h5>
                <Link className="button" to="#">
                  READ MORE
                </Link>
              </h5> */}
            </div>
          </div>
          <div className="col-sm-5">
            <div className="about_h1r clearfix">
            <EditCMSWrapper
                  imageProps={{className:"iw"}}
                  type="image"
                  dataKey="about_h9"
                />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default AboutComponent