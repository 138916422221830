import React from "react";

const SETTER_RADIUS_RATIO = 0.8;
const SETTER_STROKE_RATIO = 1 / 3;
const TRACK_SIZE_DEGREES = 270;
const TRACK_WIDTH_PX = 7;
const VIEW_BOX_SIZE_PX = 100;

const INITIAL_VALUE_PERCENTAGE = 0;

export default function RadialGauge({ value }) {
  const viewBox = `0 0 ${VIEW_BOX_SIZE_PX} ${VIEW_BOX_SIZE_PX}`;
  const setterRadius = TRACK_WIDTH_PX * SETTER_RADIUS_RATIO;
  const setterStrokeWidth = TRACK_WIDTH_PX * SETTER_STROKE_RATIO;
  const radius = VIEW_BOX_SIZE_PX / 2 - TRACK_WIDTH_PX / 2 - setterRadius;

  const cxy = VIEW_BOX_SIZE_PX * 0.5;
  const trackTransform = `rotate(${-(TRACK_SIZE_DEGREES / 2) - 90}, ${cxy}, ${cxy})`;

  const setterPos = calculateSetterPosition(radius, TRACK_SIZE_DEGREES, value);
  const setterTranslate = `translate(${setterPos.x}, ${setterPos.y})`;
  const circumference = 2 * Math.PI * radius;
  const dasharray = circumference;
  const trackFillPercentage = TRACK_SIZE_DEGREES / 360;
  const trackDashoffset = circumference * (1 - trackFillPercentage);

  const valuePercentage = (value / 100) * trackFillPercentage;
  const valueDashoffset = circumference * (1 - valuePercentage);

  return (
    <div style={{ width: 300 }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}>
        {/* Track */}
        <circle
          fill="none"
          cx="50%"
          cy="50%"
          r={radius}
          stroke="white"
          strokeDasharray={dasharray}
          strokeDashoffset={trackDashoffset}
          strokeWidth={TRACK_WIDTH_PX}
        />

        {/* Value Indicator */}
        <circle
          fill="none"
          strokeLinecap="round"
          cx="50%"
          cy="50%"
          r={radius}
          stroke="gold"
          strokeDasharray={dasharray}
          strokeDashoffset={valueDashoffset}
          strokeWidth={TRACK_WIDTH_PX}
          transform={trackTransform}
        />

        {/* Sun Symbol and Text */}
        <text
          x="50%"
          y="50%"
          fill="gold"
          textAnchor="middle"
          fontSize="25px"
          fontFamily="Arial, sans-serif"
          dy="-5"
        >
          ☀
        </text>

        <text
          x="50%"
          y="50%"
          fill="black"
          textAnchor="middle"
          fontSize="8px"
          fontWeight="bold"
          fontFamily="Arial, sans-serif"
          dy="10"
        >
          Light Radiation
        </text>
      </svg>
    </div>
  );
}

// (1)
export function calculateSetterPosition(radius, size, value) {
  return {
    x: -radius * Math.sin(degToRad((value / 100) * size + (360 - size) / 2)),
    y: radius * Math.cos(degToRad((value / 100) * size + (360 - size) / 2)),
  };
}

// (2)
export function degToRad(deg) {
  return deg * (Math.PI / 180);
}

export function calculateStepsCoords(radius, size) {
  const stepsCoords = [];
  for (let i = 0; i <= 100; i++) {
    const coords = calculateSetterPosition(radius, size, i);
    stepsCoords.push({
      value: i,
      x: coords.x,
      y: coords.y,
    });
  }
  return stepsCoords;
}