import { Box, Card, CardContent, Typography } from "@mui/material"
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge"
import React from "react"
import MainGrid from "../Dashboard/components/MainGrid"

import { Grid2 } from "@mui/material"

import CustomizedDataGrid from "../Dashboard/components/CustomizedDataGrid"
import MainGridLayout from "../Dashboard/components/MainGridLayout"
import PageViewsBarChart from "../Dashboard/components/PageViewsBarChart"
import SessionsChart from "../Dashboard/components/SessionsChart"
import StatCard, { StatCardProps } from "../Dashboard/components/StatCard"

const data: StatCardProps[] = [
  {
    title: "Azote (N)",
    value: "14",
    interval: "Last 30 days",
    trend: "up",
    data: [
      200, 24, 220, 260, 240, 380, 100, 240, 280, 240, 300, 340, 320, 360, 340,
      380, 360, 400, 380, 420, 400, 640, 340, 460, 440, 480, 460, 600, 880, 920,
    ],
  },
  {
    title: "Phosphorus (P)",
    value: "325",
    interval: "Last 30 days",
    trend: "down",
    data: [
      1640, 1250, 970, 1130, 1050, 900, 720, 1080, 900, 450, 920, 820, 840, 600,
      820, 780, 800, 760, 380, 740, 660, 620, 840, 500, 520, 480, 400, 360, 300,
      220,
    ],
  },
  {
    title: "Potassium (K)",
    value: "200",
    interval: "Last 30 days",
    trend: "neutral",
    data: [
      500, 400, 510, 530, 520, 600, 530, 520, 510, 730, 520, 510, 530, 620, 510,
      530, 520, 410, 530, 520, 610, 530, 520, 610, 530, 420, 510, 430, 520, 510,
    ],
  },
]

const NPK_Page = () => {
  const nutrients = [
    { label: "Azote (N)", value: 60, color: "#52b202" },
    { label: "Phosphorus (P)", value: 40, color: "#d32f2f" },
    { label: "Potassium (K)", value: 80, color: "#f57c00" },
  ]

  const cardStyles = {
    width: "100%",
    maxWidth: 300,
    textAlign: "center",
    padding: 2,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    transition: "transform 0.3s, box-shadow 0.3s",
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: "0px 8px 16px rgba(0,0,0,0.2)",
    },
  }

  const gaugeStyles = (color) => ({
    [`& .${gaugeClasses.valueText}`]: {
      fontSize: 28,
      fill: color,
    },
    [`& .${gaugeClasses.valueArc}`]: {
      fill: color,
    },
    [`& .${gaugeClasses.referenceArc}`]: {
      fill: "#e0e0e0",
    },
  })

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
          flexWrap: "wrap",
          marginTop: 3,
        }}
      >
        {nutrients.map((nutrient, index) => (
          <Card sx={cardStyles} key={index}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {nutrient.label}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Gauge
                  width={200}
                  height={200}
                  value={nutrient.value}
                  thickness={20}
                  sx={gaugeStyles(nutrient.color)}
                  label={`${nutrient.value}%`}
                  min={0}
                  max={100}
                />
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>
      <MainGridLayout>
        <>
          {data.map((card, index) => (
            <Grid2 key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
              <StatCard {...card} />
            </Grid2>
          ))}
          <Grid2 size={{ xs: 12, md: 6 }}>
            <SessionsChart
              title="Azote(N), Phosphorus(P), Potassium(K)"
              subTitle="Azote(N), Phosphorus(P), Potassium(K) per day for the last 30 days"
            />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <PageViewsBarChart
              title="Azote(N), Phosphorus(P), Potassium(K)"
              subTitle="Azote(N), Phosphorus(P), Potassium(K) per day for the last 6 months"
            />
          </Grid2>
          <Grid2 size={{ xs: 12, lg: 12 }}>
            <CustomizedDataGrid />
          </Grid2>
        </>
      </MainGridLayout>
    </div>
  )
}

export default NPK_Page
