import React, { ReactElement } from "react";
import { Button, IconButton, Tooltip, ButtonProps } from "@mui/material";
import { Link } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

// import i18next from "../../locales/i18n";
import {
  blueGradient,
  darkBlueGradient,
  greenGradient,
  orangeGradient,
  purpleGradient,
  redGradient,
} from "../../ressources/gradients";

interface CustomButtonProps {
  link?: string;
  text?: string;
  variant?: ButtonProps["variant"];
  color?: ButtonProps["color"];
  bgColor?: string;
  tooltipText?: string;
  onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  ButtonProps?: object;
  Icon?: ReactElement;
  children?: React.ReactNode;
  sx?: object
}

const gradients = {
  blue: blueGradient,
  green: greenGradient,
  red: redGradient,
  purple: purpleGradient,
  orange: orangeGradient,
  darkBlue: darkBlueGradient,
};

const iconMap = {
  noIcon: <></>,
  add: <AddIcon />,
  edit: <EditIcon />,
  confirm: <CheckIcon />,
  cancel: <CancelIcon />,
  submit: <CheckIcon />,
  delete: <DeleteIcon />,
  reset: <RestartAltIcon />,
};

export const CustomButton: React.FC<CustomButtonProps> = (props) => {
  const { text: providedText, tooltipText: providedTooltip, Icon, link, onClick, ButtonProps } = props;
  // const text = providedText ? i18next.t(providedText) : undefined;
  // const tooltipText = providedTooltip ? i18next.t(providedTooltip) : "";
  const text = providedText ? providedText : undefined;
  const tooltipText = providedTooltip ? providedText : "";
  const isIconButton = !!Icon && !text; // Check if only Icon is provided

  const gradient = gradients[props.bgColor ||"blue"]  ;

  const styles = {
    borderRadius: 20,
    border: 0,
    // color: "white",
    height: 48,
    padding: "0px 30px",
    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .15)",
    transition: "all 0.3s ease-in-out",
    backgroundColor: gradient,
    "&:hover": {
      transform: "scale(1.02)",
      boxShadow: "0 5px 10px rgba(0, 0, 0, .3)",
      filter: "brightness(115%)",
    },
    "&.Mui-disabled": {
      filter: "grayscale(0.5)",
    },
    ...props.sx
  };

  if (isIconButton) {
    return (
      <Tooltip title={tooltipText}>
        <IconButton
          onClick={onClick}
          {...ButtonProps}
          sx={{
            ...styles,
            width: 48,
            height: 48,
            padding: 0,
          }}
        >
          {Icon}
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={tooltipText}>
      <Button
        variant={props.variant || "contained"}
        onClick={onClick}
        startIcon={iconMap[text || 'noIcon'] || Icon}
        color={props.color}
        sx={{...styles, color: "white"}}
        {...ButtonProps}
      >
        {link ? <Link to={link}>{text}</Link> : text}
      </Button>
    </Tooltip>
  );
};
