import React, { useEffect, useRef } from "react"
import { EditCMS } from "./EditCMS"
import { useAppContext } from "../../context/AppContext"

interface EditCMSWrapperProps {
  dataKey: string 
  type?: "text" | "image" 
  imageProps?: object
}

export const EditCMSWrapper: React.FC<EditCMSWrapperProps> = ({dataKey,type, imageProps}) => {
  const { webData } = useAppContext() 
  return (
    <EditCMS
    imageProps={imageProps || {}} // Default to an empty object
    dataKey={dataKey}
      webData={webData}
      type={type}
    />
  )
}