import React, { useEffect } from "react";
import { Link } from "react-router-dom"; // Updated import
import LoginIcon from "@mui/icons-material/Login";
import { Facebook, LinkedIn, Twitter } from "../../webAppConfig";
import { EditCMSWrapper } from "../EditCMS/EditCMSWrapper";

export const Header = () => {
  useEffect(() => {
    const handleScroll = () => {
      const secondaryNav = document.querySelector(".cd-secondary-nav");
      const navbarHeight = document.querySelector(".navbar")?.offsetHeight || 0;

      if (window.scrollY > (secondaryNav?.offsetTop || 0)) {
        secondaryNav?.classList.add("is-fixed");
        document.body.style.paddingTop = `${navbarHeight}px`;
      } else {
        secondaryNav?.classList.remove("is-fixed");
        document.body.style.paddingTop = "0";
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <section id="top">
        <div className="container">
          <div className="row">
            <div className="top_1 clearfix">
              <div className="col-sm-8">
                <div className="top_1l clearfix">
                  <p className="col">
                    <EditCMSWrapper dataKey="header1" />
                  </p>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="top_1r clearfix text-right">
                  <ul className="social-network social-circle mgt">
                    <li>
                      <Link to={Facebook}>
                        <i className="fa fa-facebook" />
                      </Link>
                    </li>
                    <li>
                      <Link to={Twitter}>
                        <i className="fa fa-twitter" />
                      </Link>
                    </li>
                    <li>
                      <Link to={LinkedIn}>
                        <i className="fa fa-linkedin" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="head_t">
        <div className="container">
          <div className="row">
            <div className="head_t1 mg15 clearfix">
              <div className="col-sm-3">
                <div className="head_t1i1 clearfix">
                  <h3 className="mgt">
                    <i className="fa fa-leaf col_1" />
                    <EditCMSWrapper dataKey="header2" />
                    <br />
                    <span className="col_2 font_14">
                      <EditCMSWrapper dataKey="header3" />
                    </span>
                  </h3>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="head_t1i2 clearfix">
                  <div className="col-sm-2 space_all">
                    <div className="head_t1i2l clearfix">
                      <span className="col_1">
                        <i className="fa fa-phone" />
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-10">
                    <div className="head_t1i2r clearfix">
                      <h5 className="mgt">
                        <EditCMSWrapper dataKey="header4" />
                      </h5>
                      <h6>
                        <EditCMSWrapper dataKey="header5" />
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="head_t1i2 clearfix">
                  <div className="col-sm-2 space_all">
                    <div className="head_t1i2l clearfix">
                      <span className="col_1">
                        <i className="fa fa-envelope" />
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-10">
                    <div className="head_t1i2r clearfix">
                      <h5 className="mgt">
                        <EditCMSWrapper dataKey="header6" />
                      </h5>
                      <h6>
                        <EditCMSWrapper dataKey="header7" />
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="head_t1i2 clearfix">
                  <div className="col-sm-2 space_all">
                    <div className="head_t1i2l clearfix">
                      <span className="col_1">
                        <i className="fa fa-clock-o" />
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-10">
                    <div className="head_t1i2r clearfix">
                      <h5 className="mgt">
                        <EditCMSWrapper dataKey="header8" />
                      </h5>
                      <h6>
                        <EditCMSWrapper dataKey="header9" />
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="header" className="clearfix cd-secondary-nav">
        <nav className="navbar nav_t">
          <div className="container">
            <div className="navbar-header page-scroll">
              <button
                type="button"
                className="navbar-toggle"
                data-toggle="collapse"
                data-target="#bs-example-navbar-collapse-1"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
              <Link className="navbar-brand" to="/">
                <i className="fa fa-leaf col_1" />
                <EditCMSWrapper dataKey="header10" />
                <br />
                <span className="col_2 font_14">
                  <EditCMSWrapper dataKey="header11" />
                </span>
              </Link>
            </div>
            <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
              <ul className="nav navbar-nav">
                <li>
                  <Link className="m_tag" to="/">
                    Home
                  </Link>
                </li>
                <li>
                  <Link className="m_tag" to="/about">
                    About
                  </Link>
                </li>
                <li>
                  <Link className="m_tag" to="/services">
                    Services
                  </Link>
                </li>
                <li>
                  <Link className="m_tag" to="/project">
                    Project
                  </Link>
                </li>
                <li>
                  <Link className="m_tag" to="/faq">
                    Faq
                  </Link>
                </li>
                <li>
                  <Link className="m_tag" to="/contact">
                    Contact Us
                  </Link>
                </li>
              </ul>
              <ul className="nav navbar-nav navbar-right nav_1">
                <li>
                  <Link to="/signin">
                    <LoginIcon sx={{ fontSize: 32 }} />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </section>
    </>
  );
};
