import React, { useEffect } from "react" // Add this import

import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import { AboutUs } from "./components/frontPage/AboutUs"
import { Contact } from "./components/frontPage/Contact"
import { FAQ } from "./components/frontPage/FAQ"
import { FrontPage } from "./components/frontPage/FrontPage"
import { FrontPageLayout } from "./components/frontPage/FrontPageLayout"
import { Project } from "./components/frontPage/Project"
import { Services } from "./components/frontPage/Services"

import ThermostatIcon from "@mui/icons-material/Thermostat"

import HomeRoundedIcon from "@mui/icons-material/HomeRounded"
import Dashboard from "./components/Dashboard/Dashboard"
import { Home } from "./components/DashboardPages/Home"

import AirIcon from "@mui/icons-material/Air"
import EnergySavingsLeafIcon from "@mui/icons-material/EnergySavingsLeaf"
import HelpRoundedIcon from "@mui/icons-material/HelpRounded"
import InfoRoundedIcon from "@mui/icons-material/InfoRounded"
import LightModeIcon from "@mui/icons-material/LightMode"
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded"
import LightRadiationPage from "./components/DashboardPages/LightRadiationPage"
import { useAppContext } from "./context/AppContext"
import { fetchWebData } from "./api/axiosFunctions"
import { SignIn } from "./components/SignIn/SignIn"
import HT_EC from "./components/DashboardPages/HT_EC"
import WindSpeedPage from "./components/DashboardPages/WindSpeedPage"
import NPK_Page from "./components/DashboardPages/NPK_Page"
const mainListItems = [
  {
    text: "Home",
    icon: <HomeRoundedIcon />,
    link: "home",
    reactElement: <Home />,
  },
  {
    text: "HT_EC",
    icon: <ThermostatIcon />,
    link: "HT_EC",
    reactElement: <HT_EC />,
  },
  {
    text: "NPK",
    icon: <EnergySavingsLeafIcon />,
    link: "npk",
    reactElement: <NPK_Page/>,
  },
  {
    text: "Wind Speed",
    icon: <AirIcon />,
    link: "wind_speed",
    reactElement: <WindSpeedPage />,
  },
  {
    text: "Light Radiation",
    icon: <LightModeIcon />,
    link: "light_radiation",
    reactElement: <LightRadiationPage />,
  },
  // {
  //   text: "Analytics",
  //   icon: <AnalyticsRoundedIcon />,
  //   link: "/analytics",
  //   reactElement: <h1>Analytics</h1>,
  // },
]
const secondaryListItems = [
  { text: "Settings", icon: <SettingsRoundedIcon /> },
  { text: "About", icon: <InfoRoundedIcon /> },
  { text: "Feedback", icon: <HelpRoundedIcon /> },
]

const App = () => {
  const { webData, setValue } = useAppContext()
  useEffect(() => {
    const updateWebData = async () => {
      const data = await fetchWebData()
      setValue((prev) => ({
        ...prev,
        webData: data.webContentDataMap,
      }))
    }
    updateWebData()
  }, [])

  const navigate = useNavigate()
  return (
    <Routes>
      {/* FrontPage */}
      <Route element={<FrontPageLayout />}>
        <Route path="/" element={<FrontPage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/project" element={<Project />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/contact" element={<Contact />} />
      </Route>
      {/* Auth */}
      <Route
        path="/signin"
        element={
          <SignIn
            siteName={"Agriculture"}
            loggingFunction={() => navigate("/dashboard/home")}
            logo="/logo.png"
          />
        }
      />
      {/* Dashboard */}
      <Route
        path="/dashboard"
        element={
          <Dashboard
            siteName="Agriculture"
            logoutFunction={() => navigate("/")}
            mainListItems={mainListItems}
            secondaryListItems={secondaryListItems}
            logo="/logo.png"
            userData={{
              firstName: "Oumaima",
              lastName: "Fatnassi",
              email: "oumaima.fatnassi@gmail.com",
            }}
          />
        }
      >
        {mainListItems.map((item, key) => (
          <Route key={key} path={item.link} element={item.reactElement} />
        ))}
        {/* {secondaryListItems.map((item, key) => (
          <Route key={key} path={item.link} element={item.reactElement} />
        ))} */}
      </Route>
    </Routes>
  )
}

export default App
