import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router";
import { Header } from "./Header";
import { Footer } from "./Footer";


const includedPages = [
  "/",
  "/about",
  "/services",
  "/project",
  "/faq",
  "/contact",
];

export const FrontPageLayout = () => {
  const location = useLocation();

  useEffect(() => {
    const loadCSS = (id, href) => {
      if (!document.getElementById(id)) {
        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.type = "text/css";
        link.href = href;
        link.id = id;
        document.head.appendChild(link);
      }
    };

    const unloadCSS = (id) => {
      const link = document.getElementById(id);
      if (link) {
        document.head.removeChild(link);
      }
    };

    if (includedPages.includes(location.pathname)) {
      loadCSS("bootstrap-css", "./css/bootstrap.min.css");
      loadCSS("font-awesome-css", "./css/font-awesome.min.css");
      loadCSS("global-css", "./css/global.css");
      loadCSS("index-css", "./css/index.css");
    } else {
      unloadCSS("bootstrap-css");
      unloadCSS("font-awesome-css");
      unloadCSS("global-css");
      unloadCSS("index-css");
    }

    // Cleanup function for unmounting
    return () => {
      unloadCSS("bootstrap-css");
      unloadCSS("font-awesome-css");
      unloadCSS("global-css");
      unloadCSS("index-css");
    };
  }, [location]);

  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};
