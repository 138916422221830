import { Box, Card, CardContent } from "@mui/material"
import { Chart } from "react-google-charts"

import { Grid2 } from "@mui/material"

import CustomizedDataGrid from "../Dashboard/components/CustomizedDataGrid"
import MainGridLayout from "../Dashboard/components/MainGridLayout"
import PageViewsBarChart from "../Dashboard/components/PageViewsBarChart"
import SessionsChart from "../Dashboard/components/SessionsChart"
import StatCard, { StatCardProps } from "../Dashboard/components/StatCard"

const data = [
  {
    title: "Wind Speed",
    value: "14",
    interval: "Last 30 days",
    trend: "up",
    data: [
      200, 24, 220, 260, 240, 380, 100, 240, 280, 240, 300, 340, 320, 360, 340,
      380, 360, 400, 380, 420, 400, 640, 340, 460, 440, 480, 460, 600, 880, 920,
    ],
  },
]
function WindSpeedPage() {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

            textAlign: "center",
            boxShadow: "0px 4px 8px rgba(0,0,0,0.2)",
            padding: 2,
          }}
        >
          <CardContent>
            <Chart
              height={250}
              chartType="Gauge"
              loader={<div>Loading Chart...</div>}
              data={[
                ["Label", "Value"],
                ["Wind Speed", 50],
              ]}
              options={{
                redFrom: 90,
                redTo: 200,
                yellowFrom: 50,
                yellowTo: 90,
                minorTicks: 5,
                min: -200,
                max: 200,
              }}
            />
          </CardContent>
        </Card>
      </Box>
      <MainGridLayout>
        <>
          {data.map((card, index) => (
            <Grid2 key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
              <StatCard {...card} />
            </Grid2>
          ))}
          <Grid2 size={{ xs: 12, md: 6 }}>
            <SessionsChart
              title="Wind Speed"
              subTitle="Wind Speed per day for the last 30 days"
            />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <PageViewsBarChart
              title="Wind Speed"
              subTitle="Wind Speed per day for the last 6 months"
            />
          </Grid2>
          <Grid2 size={{ xs: 12, lg: 12 }}>
            <CustomizedDataGrid />
          </Grid2>
        </>
      </MainGridLayout>
    </div>
  )
}

export default WindSpeedPage
